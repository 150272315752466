.comments-text {
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em; /* Ensure consistent line height */
    max-height: 3em; /* Restrict to 2 lines (2 * line-height) */
    cursor: pointer; /* Indicate hoverable area */
    transition: all 0.3s ease;
    background-color: white !important;
  }
  
  .comments-text:hover {
    -webkit-line-clamp: unset; /* Disable clamping */
    max-height: 100% !important; /* Allow full text to show */
    overflow: visible !important;
    white-space: normal;
    background-color: white !important;
  }
  
.status-box {
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 12px;
}

.status-box:hover {
  background-color: #e0e0e0;
}

.status-box.active {
  background-color: #613AE7;
  color: white;
}

.status-box .count {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.9em;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table th {
  background-color: #f5f5f5;
  padding: 12px;
  text-align: left;
  font-weight: 600;
}

.table td {
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
}

.table tbody tr:hover {
  background-color: #f8f9fa;
}

.btn-primary {
  background-color: #613AE7;
  border-color: #613AE7;
}

.btn-primary:hover {
  background-color: #4f2ed9;
  border-color: #4f2ed9;
}

.form-control:focus {
  border-color: #613AE7;
  box-shadow: 0 0 0 0.2rem rgba(97, 58, 231, 0.25);
}