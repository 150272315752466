body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "PrimaryRegular";
  src: local("PrimaryRegular"),
   url("./fonts/Ubuntu-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PrimaryBold";
  src: local("PrimaryBold"),
   url("./fonts/Ubuntu-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "PrimarySemiBold";
  src: local("PrimarySemiBold"),
   url("./fonts/Ubuntu-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PrimaryRegularItalic";
  src: local("PrimaryRegularItalic"),
   url("./fonts/Ubuntu-Italic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PrimarySemiBoldItalic";
  src: local("PrimarySemiBoldItalic"),
   url("./fonts/Ubuntu-MediumItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PrimaryLight";
  src: local("PrimaryLight"),
   url("./fonts/Ubuntu-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PrimaryBoldItalic";
  src: local("PrimaryBoldItalic"),
   url("./fonts/Ubuntu-BoldItalic.ttf") format("truetype");
  font-weight: normal;
}